<template>
    <div>
        <div class="search_history">
            <el-form label-width="80px">
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h2>{{$t('message.personcenter.retirevinghistory')}}</h2>
                        </div>
                    </template>
                    <div style="margin-left: 5%;width: 300%;" >
                        <el-form-item :label="$t('message.personcenter.inforcategory')">
                            <el-radio-group v-model="radio">
                                <el-radio :label="0">{{$t('message.personcenter.all')}}</el-radio>
                                <el-radio :label="1">{{$t('message.personcenter.tonnage')}}</el-radio>
                                <el-radio :label="2">{{$t('message.personcenter.cargo')}}</el-radio>
                                <el-radio :label="3">{{$t('message.personcenter.tct')}}</el-radio>
                        </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('message.personcenter.keyword')">
                            <el-col :span="12">
                                <el-autocomplete v-model="guanjianzi" style="width: 75%;"></el-autocomplete>
                            </el-col>
                            <el-col :span="12">
                                <el-button size="small" type="primary" style="margin-left:30% ">{{$t('message.personcenter.inquire')}}</el-button>
                            </el-col>
                        </el-form-item>
                    </div>
                    <div><h2>{{$t('message.personcenter.resultlist')}}</h2></div>
                    <div class="table_box">
                        <el-table
                                :data="tableData"
                                style="width: 100%">
                            <el-table-column
                                    prop="guanjianzi"
                                    :label="$t('message.personcenter.retrievecondition')"
                                    width="300px">
                            </el-table-column>
                            <el-table-column
                                    prop="result"
                                    :label="$t('message.personcenter.result')"
                                    width="300px">
                            </el-table-column>
                            <el-table-column
                                    prop="date"
                                    :label="$t('message.personcenter.retrievetime')"
                                    width="300px">
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    :label="$t('message.handle')">
                                <el-button size="mini" type="danger">{{$t('message.personcenter.delete')}}</el-button>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </el-form>
        </div>

        <!--<div>-->
            <!--<el-card class="box-card">-->
                <!--<template #header>-->
                    <!--<div class="card-header">-->
                        <!--<span>检索历史</span>-->
                    <!--</div>-->
                <!--</template>-->
                <!--<div class="table_box">-->
                    <!--<el-table-->
                            <!--:data="tableData"-->
                            <!--style="width: 100%">-->
                        <!--<el-table-column-->
                                <!--prop="guanjianzi"-->
                                <!--label="检索条件"-->
                                <!--width="300px">-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="result"-->
                                <!--label="结果"-->
                                <!--width="300px">-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="date"-->
                                <!--label="检索时间"-->
                                <!--width="300px">-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->
                                <!--prop="address"-->
                                <!--label="操作">-->
                            <!--<el-button size="mini" type="danger">删除</el-button>-->
                        <!--</el-table-column>-->
                    <!--</el-table>-->
                <!--</div>-->

            <!--</el-card>-->
        <!--</div>-->
    </div>
</template>

<script>
  export default {
    data(){
      return {
        radio:0,
        tableData:[
          {
            guanjianzi:'大连',
            result:'船舶动态信息',
            date:'2021-07-21'
          }
        ]
      }
    }
  };
</script>

<style lang="scss">
    .search_history{
        margin-bottom: 30px;
    }

    .table_box{
        margin-left: 5%;
        width:80%;
    }
</style>